html {
  padding: 0px;
  margin: 0px;
  width: 100%;
  overflow-x: hidden;
  font-size: 50%;
  /* background-color: black; */
}

* {
  margin: 0;
  padding: 0;
  font-family: Poppins;
}

.screen {
  width: 45rem;
  height: 92rem;
}

.common-h1 {
  color: #ffffff;
  font-size: 6rem;
  font-weight: bold;
}

.common-p {
  color: #ffffff;
  font-size: 2.6rem;
  font-weight: 300;
}

.canvas {
  z-index: -1;
  position: absolute;
  width: 100%;
  // height: fit-content;
  padding-bottom: 20px;
}

.phone {
  display: none;
}

.desktop {
  display: block;
}

a {
  text-decoration: none;
}

.hide {
  opacity: 0;
}
button:hover {
  cursor: pointer;
}

button {
  margin-top: 6rem;
  background-color: #fff;
  width: 20.1rem;
  height: 4.7rem;
  border-radius: 2.9rem;
  color: #3696d2;
  font-size: 2.2rem;
  border-width: 0;
}

.button {  
    
    background-color: #fff;
    width: 33rem;
    height: 6rem;
    border: 3px solid transparent;
    border-radius: 7rem;
    font-size: 3.2rem;
    font-weight: 600;
    color: #3696d2;
    display: block;
    transition: all 0.3s ease-in-out;
  }

  .button:hover {
    background-color: transparent;
    color: #fff;
    border: 3px solid #fff;
  }

@media (max-width: 600px) {
  .screen {
    width: 11rem;
    height: 21.8rem;
  }

  .common-h1 {
    font-size: 2.7rem;
    text-align: center;
  }

  .common-p {
    font-size: 2.4rem;
  }

  .desktop {
    display: none;
  }

  .phone {
    display: block;
    margin: 0 auto;
  }

  button {
    margin: 0 auto;
    margin-top: 2rem;
    width: 21rem;
    height: 3.7rem;
    border-radius: 3.6rem;
    font-size: 1.5rem;
  }
}
