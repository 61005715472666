/* Base styles for the footer */
.footer {
  // padding-top: 25rem;
  // padding-top: 0.1rem;
  height: fit-content;
  background-color: #ffffff;
  .footer-inside {
    width: 100%;
    display: block;
    // margin: 0 auto;

    .footer-content {
      margin: 6rem 0 5rem 5rem;

      .footer-links {
        display: flex;
        flex-direction: column;

        .footer-logo-location {
          margin-top: 5rem;
          img {
            width: 5.3rem;
            height: 5.3rem;
          }

          .footer-location {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            margin-left: 6rem;
            margin-top: 2rem;

            a {
              font-size: 2.4rem;
              text-decoration: none;
            }
          }

          a {
            // line-height:5rem;
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 3.2rem;
              // line-height: 7rem;
              color: #1c2b5e;
            }
          }
        }

        // margin-top: 9.2rem;
        display: flex;
        justify-content: space-between;

        .footer-link {
          position: relative;

          h5 {
            font-weight: 600;
            font-size: 3rem;
            // line-height: 4.5rem;
            color: #1c2b5e;
          }

          div {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            a {
              margin-top: 1.3rem;
              text-decoration: none;
              font-size: 2.4rem;
              font-weight: 500;
              transition: all 0.2s ease-out;
            }

            .hover:hover{
              color: #2543a5;
              text-decoration: underline;
              transform: translateX(1rem);
            }
          }
        }
      }

      .footer-subscribe {
        margin-top: 9.2rem;

        div {
          display: block;
        }

        h5 {
          font-weight: 600;
          font-size: 3rem;
          margin-top: -2.5rem;
          // line-height: 4.5rem;
        }

        .footer-links-subscribe {
          margin-top: 1.2rem;

          .footer-input {
            height: 6.8rem;
            width: 44rem;
            background-color: #e9f4fa;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: 1.1rem;

            input {
              text-decoration: none;
              width: 100%;
              height: 4rem;
              border-width: 0;
              border: none;
              background-color: #e9f4fa;
              margin: 1.3rem 1rem 1.3rem 2.7rem;
              font-size: 2rem;
            }

            input:focus {
              outline: none;
              border: none;
            }

            button {
              margin: 2rem 1rem 1rem 10rem;
              border: none;
              background-color: transparent;
              // max-width:2rem ;

              img {
                width: 3rem;
                height: 3.1rem;
                margin-left: 10px;
              }

              :hover {
                cursor: pointer;
              }
            }
          }

          .footer-socialmedia-links {
            margin-top: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            a {
              margin-right: 2.1rem;

              img {
                width: 5.2rem;
                height: 5.2rem;
                color: #ffffff;
                transition: all 0.2s ease-out;
              }

              img:hover{
                cursor: pointer;
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }

    .footer-border {
      border: 2px solid rgba(31, 137, 202, 0.32);
      opacity: 0.32;
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    .footer-copy-rights {
      display: flex;
      // height: 6rem;
      justify-content: center;
      font-size: 2rem;
      align-items: center;
      // margin: 0rem auto;

      a {
        font-weight: 400;
      }

      img {
        margin-right: 1.2rem;
        width: 3rem;
        height: 3rem;
      }
    }
  }
}

.footer-about{
  margin-top: 2rem;
  a{
    line-height: 5rem;
  }
}

.footer-more {
  position: relative;
  margin-top: 2rem;
}

.footer-playStoreButton {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 14rem;

  a {
    margin-right: 5rem;
    img {
      width: 19rem;
      height: 6rem;
    }
  }
}

@media (max-width: 800px) {
  .footer {
    .footer-inside {
      width: auto;
      display: block;
      margin: 2rem 0rem;

      .footer-content {
        flex-direction: column;
        justify-content: start;
        margin: 0 0;

        .footer-links {
          margin-right: 0;
          justify-content: space-between;
          width: auto;

          .footer-logo-location {
            .footer-location {
              display: flex;
              justify-content: flex-start;
              flex-direction: column;

              a {
                padding-top: 0;
                font-size: 2.2rem;
                line-height: 3rem;
                width: auto;
                text-decoration: none;
              }
            }

            a {
              img {
                width: 5.1rem;
                height: 4.7rem;
                z-index: -1;
              }
            }
          }

          margin-top: 0;

          .footer-link {
            h5 {
              font-weight: 600;
              font-size: 2.6rem;
              line-height: auto;
            }

            div {
              margin-top: 0;

              a {
                margin-top: 0;
                text-decoration: none;
                font-size: 2rem;
              }
            }
          }
        }

        .footer-subscribe {
          margin-top: 3.5rem;
          width: 100%;
          div {
            display: block;
          }

          h5 {
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 2rem;
          }

          .footer-links-subscribe {
            display: flex;
            margin-top: 1.5rem;

            .footer-input {
              height: 6rem;
              width: 90%;
              border-radius: 1.1rem;
              justify-content: start;
              margin-top: 1.5rem;

              input {
                width: 100%;
                height: 2rem;
                margin: 1.3rem 1rem 1.3rem 2.7rem;
                font-size: 1.4rem;
              }
              input::placeholder {
                font-size: 2rem;
              }

              button {
                margin: 0 2rem 0 0;
                border: none;
                background-color: transparent;
                width: auto;

                img {
                  transform: translateY(0.3rem);
                  width: 1.927rem;
                  height: 1.927rem;
                }
              }
            }

            .footer-socialmedia-links {
              margin-top: 3rem;
              margin-bottom: 2rem;
              margin-left: 0rem;

              a {
                img {
                  width: 5.6rem;
                  height: 5.6rem;
                }
              }
            }
          }
        }
      }

      .footer-copy-rights {
        font-size: 1.6rem;
        margin: 3rem auto;

        img {
          margin-right: 1.2rem;
          width: 2.6rem;
          height: 2.6rem;
        }
      }
    }
  }
}

@media (max-width: 899px) {



  .footer-playStoreButton {
    display: flex;
    justify-content: flex-start;
    margin-top: 6rem;
    margin-left: 7rem;

    a {
      img {
        width: 24rem;
        height: 8rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .footer-playStoreButton {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-left: 4rem;
    padding: 1rem;
    a {
      img {
        width: 20rem;
        height: 8rem;
      }
    }
  }
}
