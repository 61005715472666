.accounts {
    margin-top: 20rem;
    margin-bottom: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .accounts-inside {
    display: flex;
    justify-content: space-between;
    margin: 0 23rem;

    .accounts-content {
      width: 85rem;
      height: 61rem;

      .accounts-choose {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 2.7rem;

        color: #ADADAD;
      }

      .accounts-icons {
        margin-top: 3.5rem;
        display: flex;
        justify-content: space-between;
        div {
          display: flex;
          flex-direction: column;

          p {
            margin: 3rem auto 0 auto;
            margin-top: 3rem;
          }

          div {
            width: 12.6rem;
            height: 12.6rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .accounts-public {
          background-color: #d878ff;
          img {
            width: 6.1rem;
            height: 6.2rem;
          }
        }

        .accounts-family {
          background-color: #8b6cd8;
          img {
            width: 5rem;
            height: 5rem;


            width: 6.1rem;
            height: 6.1rem;
          }
        }

        .accounts-friends {
          background-color: #ffa621;
          img {
            width: 6.1rem;
            height: 5.5rem;
          }
        }

        .accounts-work {
          background-color: #1f89ca;
          img {
            width: 6.1rem;
            height: 4.5rem;
          }
        }
      }

      p {
        margin-top: 6rem;
        font-weight: 500;
        margin: 2rem 0 2rem 0.4rem;
      }

      .accounts-discription {
        font-weight: 600;
        height: auto;
        background: #e9eaf8;
        font-size: 2rem;
        width: 74.6rem;
        padding: 4rem;
        margin: 0 auto;
        margin-top: 6rem;
        border-radius: 1.7rem;
        color: #d878ff;
      }
    }
  }
}

@media (max-width: 600px) {
  .accounts {
    height: auto;
    .accounts-inside {
      margin: 3rem;
      padding-bottom: 2rem;

      .accounts-content {
        width: auto;
        margin-top: 0;

        h1 {
          margin-bottom: 3rem;
        }

        .accounts-icons {
          margin-top: 1.5rem;
          div {
            display: flex;
            flex-direction: column;

            p {
              margin: 1.5rem auto 3rem auto;
            }

            div {
              width: 7.3rem;
              height: 7.3rem;
              border-radius: 50%;
            }
          }

          .accounts-public {
            img {
              width: 3rem;
              height: 3.2rem;
            }
          }

          .accounts-family {
            img {
              width: 3rem;
              height: 3rem;
            }
          }

          .accounts-friends {
            img {
              width: 3rem;
              height: 3.1rem;
            }
          }

          .accounts-work {
            img {
              width: 4.3rem;
              height: 3.2rem;
            }
          }
        }

        p {
          margin-top: 2.5rem;
          font-weight: 500;
        }

        .accounts-discription {
          font-size: 1rem;
          width: auto;
          padding: 1.5rem;
          margin: 0 auto 3rem auto;
        }
      }
    }
  }
}

.screen-animate-accounts {
  animation: screen_animate_accounts 1s;
}

@keyframes screen_animate_accounts {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
