.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .navbar-inside {
    width: 100%;
    height: 10rem;
    // padding: 3rem 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    margin-left: 10rem;

    .navbar-logo {
      a {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 0rem;
        div {
          width: 6rem;
          height: 6rem;
          background-color: white;
          border-radius: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 2rem;

          img {
            top: 50%;
            width: 3.5rem;
            height: 3.7rem;
          }
        }

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 3.6rem;
          line-height: 5.4rem;
          color: #ffffff;
        }
      }
    }

    .navbar-links {
      width: 65rem;
      position: relative;
      display: flex;
      justify-content: flex-end;
      gap: 6rem;
      margin-left: auto;
      margin-right: 2rem;

      a {
        text-decoration: none;
        font-size: 2.5rem;
        color: #ffffff;
        font-family: Inter;
        font-style: normal;
        position: relative;
        letter-spacing: 0.5px;
        color: #fff;
        font-weight: 600;
        // font-weight: bold;
      }

      a::after{
        content: '';
        position: absolute;
        background-color: #fff;
        height: 2px;
        width: 0;
        bottom: -6px;
        left: 0;
        transition: all 0.2s ease-in-out;
      }

      a:hover::after{
        width: 100%;
      }
    }


    // .navbar-theme {
    //   width: 10rem;
    //   height: auto;
    //   position: relative;
    //   display: flex;
    //   justify-content: flex-end;
    //   margin-right: 2rem;

    //   .navbar-theme-icon {
    //     height: 5.1rem;
    //     width: 5.1rem;
    //   }
    //   :hover {
    //     cursor: pointer;
    //   }
    // }
  }
}

@media (max-width: 600px) {
  .navbar {
    margin-bottom: 10rem;
    .navbar-inside {
      height: auto;
      margin-left: 1rem;
      margin-top: 1rem;
      .navbar-logo {
        a {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-right: 0rem;
          div {
            width: 5rem;
            height: 5rem;
            background-color: white;
            border-radius: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;  
            img {
              top: 50%;
              width: 3.5rem;
              height: 3.7rem;
            }
          }
  
          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 2.3rem;
            line-height: 2.4rem;
            color: #ffffff;
          }
        }
      }

      .navbar-links {
        // width: 28.4rem;
        width: 30.4rem;
        a {
          font-size: 2rem;
          // margin-left: 1rem;
          // justify-content: center;
        }

        a::after{
          height: 2px;
        }
      }

      // .navbar-theme {
      //   width: 4.5rem;
      //   margin-left: 0rem;
      //   .navbar-theme-icon {
      //     width: 4rem;
      //     height: 4rem;
      //     margin-left: 1rem;
      //   }
      // }
    }
  }
}
