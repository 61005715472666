.dataPresent {
  margin: 20rem auto 19rem auto;
  background-color: white;

  .datpresent-content {
    display: flex;
    justify-content: space-between;
    width: 145rem;
    margin: auto;

    div {
      .dataPresent-number {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 4.8rem;

        color: #1c2b5e;
      }


        .dataPresent-para {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        color: #1c2b5e;
        
    }
    }
  }
}

@media (max-width: 900px){
  .dataPresent {
    margin: 20rem auto 19rem auto;
    background-color: white;
  
    .datpresent-content {
      display: flex;
      justify-content: space-between;
      width: 145rem;
      margin: auto;
  
      div {
        .dataPresent-number {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 4.8rem;
  
          color: #1c2b5e;
        }
  
  
          .dataPresent-para {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          color: #1c2b5e;
          
      }
      }
    }
  }
}