.landingPage {
  width: 100%;
  align-items: center;
  position: relative;

  .landingPage-container {
    // padding: 3rem 0 15rem 0;

    .landingPage-heading {
     width: 100%;
     margin: 0 auto;
     font-weight: 900;
     font-size: 3rem;
     text-align: center;
     color: #ffffff;
     padding-bottom: 10rem;
    }

    .landingPage-images {
      height: 70rem;
      transform: translateY(1rem);
      // margin: 4rem 0 4rem 0;
      margin-top: -12rem;

      img {
        margin: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .screen1-image {
        width: 32.7rem;
        height: 59.3rem;
        // width: 42.7rem;
        // height: 87.3rem;
        // width: 45rem;
        // height: 90rem;
      }

      .screen2-image-left {
        left: 37.5%;
      }

      .screen2-image-right {
        left: 62.5%;
      }

      .screen3-image-left {
        left: 28.5%;
      }

      .screen3-image-right {
        left: 71.5%;
      }

      .screen2-image {
        width: 23.7rem;
        height: 53.3rem;
        // width: 36rem;
        // height: 80rem;
      }

      .screen3-image {
    //     width: 24.75rem;
    // height: 64.37rem;
        // width: 27.2rem;
        // height: 72.2rem;
        width: 23.7rem;
        height: 47.3rem;
      }
    }

    .landingPage-text {
      font-size: 2.5rem;
      font-weight: 500;
      margin: 0 auto;
      text-align: center;
      color: #ffffff;
    }

    button {  
      margin: 0 auto;
      margin-top:-2rem;
      background-color: #fff;
      width: 30rem;
      height: 7rem;
      border: 3px solid transparent;
      border-radius: 7rem;
      font-size: 3rem;
      font-weight: 600;
      color: #3696d2;
      display: block;
      transition: all 0.3s ease-in-out;
    }

    button:hover {
      background-color: transparent;
      color: #fff;
      border: 3px solid #fff;
    }
   
  }
}

.popup {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  position: fixed;
  color: #fff;

  .popup-inside {
    align-items: center;
    width: 110rem;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -47%);
    border-radius: 3rem;

    .popup-heading {
      text-align: center;
      height: 22rem;
      background-color: #1d2c5f;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        text-align: center;
        font-size: 4.8rem;
        font-weight: bold;
      }
    }

    .popup-content {
      padding: 5rem 18rem;
      background-color: #fff;
      color: black;

      div {
        .popup-form {
          h5 {
            font-size: 2.4rem;
            font-weight: 500;
          }

          input {
            background: #f7f7f7;
            border: 1px solid #dfdfdf;
            box-sizing: border-box;
            border-radius: 10px;
            width: 100%;
            height: 8rem;
            color: #807c7c;
            font-size: 2.4rem;
            margin-top: 1.5rem;
            padding-left: 2.5rem;
          }

          .popup-phone {
            margin-top: 2rem;
          }

          button {
            margin: 0 auto;
            margin-top: 5.6rem;        ;
            margin-left: 21rem;
            width: 27rem;
            height: 6rem;
            background-color: #3696d2;
            border-radius: 6.9rem;
            border-width: 0;
            text-align: center;
            font-size: 3rem;
            color: #fff;
          }
        }
      }
    }

    .closePng {
      position: absolute;
      right: 0;
      transform: translate(50%, -50%);
      width: 6rem;
      height: 6rem;
    }
  }
}

@media (max-width: 600px) {
  .landingPage {
    height: auto;
    padding-bottom: 5rem;

    .landingPage-container {
      margin: 0 2rem;
      padding: 0 0 0 0;

      .landingPage-heading {
        width: auto;
        margin: 0 auto;
        height: auto;
        font-size: 2rem;
      }

      .landingPage-images {
          height: 30rem;
        .screen1-image {
          width: 10.6rem;
          height: 21.6rem;
        }

        .screen2-image {
          width: 9.6rem;
          height: 19.2rem;
        }

        .screen3-image {
          width: 7.2rem;
          height: 17.5rem;
        }
      }

      .landingPage-text {
        font-size: 1.6rem;
      }

      button {
        margin-top: 2.7rem;
        width: 21rem;
        height: 3.7rem;
        font-size: 1.6rem;
        margin-bottom: 3rem;
      }
    }
  }

  .popup {
    .popup-inside {
      width: 55rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -47%);

      .popup-heading {
        height: 11rem;

        h4 {
          font-size: 2.4rem;
        }
      }

      .popup-content {
        padding: 2rem 8rem;

        div {
          .popup-form {
            h5 {
              font-size: 1.2rem;
            }

            input {
              border: 0.5px solid #dfdfdf;
              border-radius: 5px;
              height: 4rem;
              font-size: 1.2rem;
              margin-top: 0.7rem;
              padding-left: 1.7rem;
            }

            .popup-phone {
              margin-top: 1rem;
            }

            button {
              margin: 0 auto;
              margin-top: 2rem;
              width: 21rem;
              height: 3.7rem;
              border-radius: 3.6rem;
              font-size: 1.5rem;
            }
          }
        }
      }

      .closePng {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}

.error {
  border-color: tomato !important;
  border-width: 0.3rem;
  color: red !important;
}


.screen1-image {
 animation: screen1_image_anime 1s;
}

@keyframes screen1_image_anime {
  0% {
    transform: translate(-50%, -10%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

.screen2-image-right {
 animation: screen2-image-right 1s;
}

@keyframes screen2-image-right {
  0% {
    transform: translate(-100%, -10%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

.screen3-image-right {
 animation: screen3-image-right 1s;
}

@keyframes screen3-image-right {
  0% {
    transform: translate(-150%, -10%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}


.screen2-image-left {
 animation: screen2-image-left 1s;
}

@keyframes screen2-image-left {
  0% {
    transform: translate(0%, -10%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

.screen3-image-left {
 animation: screen3-image-left 1s;
}

@keyframes screen3-image-left {
  0% {
    transform: translate(50%, -10%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}