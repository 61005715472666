// .news {
//   height: fit-content;
//   .news-inside {
//     .news-icons-field {
//       width: 155.3rem;
//       margin: 8rem auto 8rem auto;

//       .news-heading {
//         font-family: "Poppins";
//         font-style: normal;
//         font-weight: 700;
//         font-size: 4rem;
//         color: #ffffff;
//         text-align: center;
//         margin-bottom: 5rem;
//       }

//       .news-subheading {
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 400;
//         font-size: 2.8rem;
//         text-align: center;
//         color: #ffffff;
//         margin-bottom: 9rem;
//       }

//       .news-icon-row {
//         display: flex;
//         justify-content: space-between;
//         text-align: center;

//         div {
//           margin-bottom: 11.8rem;

//           .news-icon {
//             width: 12rem;
//             height: 12rem;
//             border-radius: 50%;
//             margin: auto;
//             margin-bottom: 2.5rem;
//             background-color: white;
//             align-items: center;
//             display: flex;
//             justify-content: center;

//             img {
//               width: 7.5rem;
//               height: 7.5rem;
//             }
//           }

//           h5 {
//             font-size: 3rem;
//             font-weight: 700;
//             width: 35rem;
//             margin-bottom: 4.6rem;
//             color: #ffffff;
//             text-align: center;
//             margin-left: 4rem;
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 400;
//           }

//           p {
//             font-size: 2.3rem;
//             width: 41.4rem;
//             color: #ffffff;
//             text-align: center;
//             font-family: "Poppins";
//             font-style: normal;
//           }
//         }
//       }
//     }
//   }
// }

// .explore-button{
//   display: flex;
//   justify-content: flex-end;
// }

// @media (max-width: 600px) {
//   .news {
//     .news-inside {
//       .news-icons-field {
//         width: 90%;
//         .news-icon-row {
//           div {
//             margin-bottom: 3rem;

//             .news-icon {
//               margin-bottom: 1.3rem;

//               img {
//                 width: 4.7rem;
//                 height: 4.7rem;
//               }
//             }

//             h5 {
//               font-size: 1.5rem;
//               width: 13rem;
//               margin-bottom: 0.6rem;
//             }

//             p {
//               font-size: 1.05rem;
//               width: 13rem;
//             }
//           }
//         }
//       }

//       .news-news {
//         .news-articles-container {
//           margin: 0 3rem;
//           padding: 5rem 0 5rem 0;
//           h1 {
//             font-size: 2rem;
//             margin-bottom: 3.3rem;
//           }

//           .news-articles {
//             justify-content: center;
//             flex-direction: column;

//             div {
//               margin: auto;
//               margin-bottom: 3rem;
//               width: 38.3rem;
//               height: auto;
//               padding: 2.1rem 2.5rem;

//               img {
//                 margin: auto;
//                 width: 38.3rem;
//                 height: 23.6rem;
//               }

//               h5 {
//                 font-size: 2.2rem;
//                 margin-top: 1.8rem;
//                 margin-bottom: 0.7rem;
//               }

//               p {
//                 font-size: 1.6rem;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

.button-container{
    width: 100vw;
    height: fit-content;
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
}

.button {
    margin-top: 10rem;
    background-color: #fff;
    width: 30rem;
    height: 7rem;
    border-radius: 7rem;
    padding: 10px;
    border: 3px solid transparent;
    font-size: 3rem;
    font-weight: 600;
    color: #3696d2;
    right: 0;
    display: block;
    text-decoration: none;
    text-transform: none;
    margin-left: 10rem;
  }

  .button:hover {
    background-color: transparent;
    color: #fff;
    border: 3px solid #fff;
  }