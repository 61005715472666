.blueComponenet {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .blueComponenet-inside {
    display: flex;
    justify-content: flex-start;
    width: 150rem;
    margin: 20rem auto;
    padding: 0rem 0;

    .blueComponenet-text {
      width: 100rem;
      display: flex;
      // justify-content: center;
      flex-direction: column;
      margin: auto 0;
      margin-right: 17rem;

      h1 {
        font-size: 4rem;
      }

      .points {

        margin-top: 6rem;
      }
      

      p {
        color: #ffffff;
        font-weight: 300;
        font-size: 2.6rem;
      }

      .blueComponent-subheading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 3rem;
        color: #FFFFFF; 
        margin-top: 3rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .blueComponenet {
    min-height: auto;

    .blueComponenet-inside {
      padding: 5rem 0;
      width: auto;
      margin: 0 3rem;

      .blueComponenet-text {
        width: 100%;
        margin-right: 0;

        h1 {
          width: auto;
          font-weight: bold;
          line-height: auto;
          margin-bottom: 3rem;
        }

        p {
          width: auto;
          height: auto;
          font-size: 1.6rem;
        }

        .points {
          margin-top: 4.5rem;
        }

        a {
          text-align: center;
        }
        .screen-container {
          height: 65rem;
          width: auto;
          position: relative;
        }
        
        .screen {
          height: 60rem;
          width: 40rem;
          position: absolute;
          left: 40%;
        }

      }
    }
  }
}

.screen-container {
  height: auto;
  width: auto;
}

.screen {
  height: 80rem;
  width: 60rem;
  margin-top: 10rem;
  margin-left:-15rem;
}



.screen-animate-blueComponent {
  animation: screen_animate_blueComponent 1s;
}

@keyframes screen_animate_blueComponent {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
