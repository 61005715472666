.whiteComponent {
  width: 100%;
  margin: 0rem 0 0rem 0;
  display: flex;
  justify-content: center;

  .whiteComponent-inside {
    width: 150rem;
    margin: auto auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .white-img {
       width: 150rem;
    }

    .whiteComponent-text {
      width: 100rem;
      height: auto;
      display: flex;
      margin: auto 0;
      flex-direction: column;

      h1 {
        font-weight: bold;
        font-size: 4rem;
      }

      .points {

        margin-top: 6rem;
      }

      p {
        font-weight: 300;
        font-size: 3.6rem;
      }

      .blueComponent-subheading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 3rem;
        margin-top: 3rem;
        color: white;
      }
    }
  }
}
.screen-container {
  height: auto;
  width: auto;
}
.screen {
  height: 80rem;
  width: 60rem;
  margin-top: 10rem;
  margin-left:-15rem;
}

@media (max-width: 600px) {
  .whiteComponent {
    min-height: auto;

    .whiteComponent-inside {
      width: auto;
      margin: 3rem;

      .whiteComponent-text {
        width: 100%;
        height: auto;

        h1 {
          margin-bottom: 3rem;
        }

        p {
          width: auto;
          height: auto;
          font-size: 1.6rem;
        }

        .points {
          margin-top: 4.5rem;
        }
      }
      .screen-container {
        height: 65rem;
        width: auto;
        position: relative;
      }
      
      .screen {
        height: 60rem;
        width: 40rem;
        position: absolute;
        left: 40%;
      }
    }
  }
}

.screen-animate-whiteComponent {
  animation: screen_animate_whiteComponent 1s;
}

@keyframes screen_animate_whiteComponent {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
